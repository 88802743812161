const imageCredits = document.querySelectorAll( '.biro-component-image-credit' );

if ( imageCredits ) {

  imageCredits.forEach( function( element ) {

    element.querySelector( '.credit-heading' ).addEventListener( 'click', function( event ) {

      element.classList.toggle( 'is-active' );

    } );

  } );
}
