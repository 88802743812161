// Function to close privacy notice
function privacyNoticeClose( decision ) {
	// Set a cookie with the decision that lasts 31536000 seconds (one year)
	document.cookie =
		'biro_privacy_decision=' +
		decision +
		'; ' +
		'max-age=' +
		'31536000' +
		'; ' +
		'path=' +
		'/' +
		'; ' +
		'domain=' +
		window.location.hostname;

	// Remove the privacy notice from the DOM tree
	document.querySelector( '#privacy-notice' ).remove();
}

// Attempt to get an existing cookie
let privacyCookieResult = document.cookie.match(
	'(^|[^;]+)\\s*biro_privacy_decision\\s*=\\s*([^;]+)'
);

// Declare an existing privacy decision if it exists, otherwise mark it as undefined
let privacyDecision = privacyCookieResult
	? privacyCookieResult.pop()
	: 'undefined';

// If all these are true:
if (
	typeof biro_privacy_notice !== 'undefined' && // The privacy notice object from the customizer exists
	privacyDecision === 'undefined' && // There's no existing privacy decision
	navigator.doNotTrack !== '1' && // Do Not Track is not set (most browsers)
	window.doNotTrack !== '1' // Do Not Track is not set (some versions of IE / Edge)
) {
	// Create the privacyNotice div
	let privacyNotice = document.createElement( 'div' );

	// Add the privacy-notice class
	privacyNotice.setAttribute( 'id', 'privacy-notice' );

	// Add the privacy-notice class
	privacyNotice.setAttribute( 'class', 'privacy-notice' );

	// Add the content
	privacyNotice.innerHTML =
		'<div class="container"><div class="row"><div class="col-lg-5"><h2 class="heading">Website cookies</h2></div><div class="col-lg-10"><div class="text-wrapper">' + biro_privacy_notice.privacy_notice_text + ' <a href="' + biro_privacy_notice.privacy_policy_permalink + '">' + biro_privacy_notice.privacy_policy_link_text + '</a></div><div class="button-links-wrapper"><button id="privacy-accept" class="button-accent-solid accept">Accept</button><button id="privacy-reject" class="button-dark-outline reject">Reject</button></div></div></div></div>';

	// Insert the privacy notice
	document.body.insertBefore( privacyNotice, document.body.firstChild );

	// Add bottom padding to body from cookie notice
	let bodyPaddingBottom = privacyNotice.offsetHeight;

	// // Try to get the notification banner
	// const notificationBanner = document.querySelector( '#component-notification-banner' );

	// // If the notification banner is there and the window is under 992px wide
	// if ( notificationBanner && window.innerWidth < 992 ) {

	// 	// Add more bottom padding to body
	// 	bodyPaddingBottom += notificationBanner.offsetHeight;

	// 	// Add more bottom padding to privacy notice
	// 	privacyNotice.style.paddingBottom = ( parseInt( window.getComputedStyle(privacyNotice).paddingBottom ) + notificationBanner.offsetHeight ) + 'px';

	// }

	// Add padding to body so notice doesn't overlay content
	document.querySelector( 'body' ).style.paddingBottom = bodyPaddingBottom + 'px';

	// Add an event listener for when the accept button is clicked
	document
		.querySelector( '#privacy-accept' )
		.addEventListener( 'click', function () {
			// Close the privacy notice and note the acceptance
			privacyNoticeClose( 'accept' );

			// Lose bottom padding
			document.querySelector( 'body' ).style.paddingBottom = '0';
		} );

	// Add an event listener for when the reject button is clicked
	document
		.querySelector( '#privacy-reject' )
		.addEventListener( 'click', function () {
			// Close the privacy notice and note the rejection
			privacyNoticeClose( 'reject' );

			// Lose bottom padding
			document.querySelector( 'body' ).style.paddingBottom = '0';
		} );
}
