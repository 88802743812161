// Get filter form
const filterForm = document.querySelector( '#filters-form' );

// Get all filters
const filterSelects = document.querySelectorAll( '#filters-form .select' );

// For each filter
filterSelects.forEach( function ( filterSelect ) {

	// Listen for a change
	filterSelect.addEventListener( 'change', function ( event ) {

		// Mark this filter as submitting
		event.currentTarget.classList.add( 'is-submitting' );

		// Start constructing the new URL
		let newUrl = filterForm.getAttribute( 'action' ) + '?';

		// Loop through the filters
		filterSelects.forEach( function ( filterSelect ) {

			// If the filter has a value
			if ( filterSelect.value != false ) {

				// If there's already a filter in the URL, add an ampersand
				if ( newUrl.includes( '=' ) ) { newUrl += '&'; }

				// Add the filter query string
				newUrl +=  filterSelect.getAttribute( 'name' ) + '=' + filterSelect.value;

			}

		} );

		// Add the filter form ID
		newUrl += '#filters-form';

		// Go to the new URL
		window.location.href = newUrl;

	} );
} );
