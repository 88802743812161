// Add a listener for the page being loaded
window.addEventListener( 'load', function( event ) {

  // Get all children of the main element
  let mainChildren = document.querySelector( '.main' ).children;

  // Loop through the children in reverse order
  for ( let i = mainChildren.length - 1; i >= 0; i-- ) {

    // If this is a content block
    if (
      ! mainChildren[i].className.includes( 'block-type-channelling' ) &&
      ! mainChildren[i].className.includes( 'block-type-hero' ) &&
      ! mainChildren[i].className.includes( 'block-type-intro' ) &&
      ! mainChildren[i].className.includes( 'block-type-listing' ) &&
      ! mainChildren[i].className.includes( 'block-type-map' ) &&
      ! mainChildren[i].className.includes( 'block-type-promo' ) &&
      mainChildren[i].nodeName !== 'SCRIPT'
    ) {

      // Add the block-background class
      mainChildren[i].classList.add( 'block-content-last' );

      // Break out of the for loop
      break;

    }
  }

} );
