/* global showOverlay, hideOverlay */

// Set constants for the search DOM nodes
const desktopSearchToggle  = document.querySelector( '#desktop-search-toggle' );
const desktopSearchWrapper = document.querySelector( '#desktop-search-wrapper' );
const desktopSearchInput   = document.querySelector( '#desktop-search-input' );
const desktopSearchSubmit  = document.querySelector( '#desktop-search-submit' );

// Set an array of focusable elements for the desktop search form
const searchFocusableElements = [
	desktopSearchToggle,
	desktopSearchInput,
	desktopSearchSubmit,
];

// Function to handle focus trapping for the desktop search form
function handleFocusTrap( event ) {

	// Set some variables
	let focusableElements = [];

	// If the search form is active
	if ( desktopSearchToggle.getAttribute( 'aria-expanded' ) === 'true' ) {

		// Use the search focusable elements array
		focusableElements = searchFocusableElements;

	} else {

		// Try to get an active menu item
		let activeDesktopSubmenuItemLink = document.querySelector( '.desktop-menu-link.has-children[aria-expanded=true]' );

		// If there's an active submenu
		if ( activeDesktopSubmenuItemLink ) {

			// Use the items in the submenu
			focusableElements = Array.from( activeDesktopSubmenuItemLink.parentNode.querySelectorAll( 'a' ) );

		}
	}

	// If site search is enabled and it's the tab key
	if ( desktopSearchToggle && event.keyCode === 9 ) {

		// Stop the tab key from moving focus
		event.preventDefault();

		// Get the index of the currently focused element
		let focusedElementIndex = focusableElements.indexOf( document.activeElement );

		// If the user is tabbing forwards
		if ( event.keyCode === 9 && ! event.shiftKey ) {

			// Try and get the next focusable element in the array
			let nextFocusableElement = focusableElements[ focusedElementIndex + 1 ];

			// If there's a next focusable element in the array
			if ( nextFocusableElement ) {

				// Focus the next element
				nextFocusableElement.focus();

			// Otherwise if there's no next focusable element
			} else {

				// Focus the first element
				focusableElements[ 0 ].focus();

			}

		// Otherwise if the user is tabbing backwards
		} else if ( event.keyCode === 9 && event.shiftKey ) {

			// If there's a previous focusable element in the array
			if ( focusedElementIndex > 0 ) {

				// Focus the previous element
				focusableElements[ focusedElementIndex - 1 ].focus();

			// Otherwise if there's no previous focusable element
			} else {

				// Focus the last element
				focusableElements[ focusableElements.length - 1 ].focus();

			}
		}
	}
}

// Bind event listener for a click to the document
document.addEventListener( 'click', function ( event ) {
	detectClickOnNodeOutsideActiveItem( event.target );
} );

// Bind event listener for an escape keydown to the document
document.addEventListener( 'keydown', function ( event ) {
	if ( event.key === 'Escape' ) {

		// If the currently selected item is a submenu link
		if ( document.activeElement.classList.contains( 'desktop-submenu-link' ) ) {

			// Focus the parent menu item
			document.activeElement.parentNode.parentNode.parentNode.querySelector( 'a' ).focus();

		}

		// Close any open desktop submenus
		closeOpenDesktopSubmenu();

		// Hide the search form
		hideSearchForm();

		// Remove the focus trap listener
		document.removeEventListener( 'keydown', handleFocusTrap );
	}
} );

// Function to detect a click outside an active menu item or search form
function detectClickOnNodeOutsideActiveItem( clickedNode ) {
	// Try to get an active menu item
	let activeDesktopSubmenuItemLink = document.querySelector(
		'.desktop-menu-link.has-children[aria-expanded=true]'
	);

	// If there's an active submenu
	if ( activeDesktopSubmenuItemLink ) {
		// If the click is anywhere outside the original menu item
		if ( ! activeDesktopSubmenuItemLink.parentNode.contains( clickedNode ) ) {
			// Close the active submenu
			closeOpenDesktopSubmenu();
		}
	}

	// If this wasn't trigger by the search toggle itself and the search form is active
	if (
		desktopSearchToggle &&
		! desktopSearchToggle.contains( clickedNode ) &&
		desktopSearchToggle.getAttribute( 'aria-expanded' ) === 'true'
	) {
		// If the click is anywhere outside the original menu item
		if ( ! desktopSearchWrapper.parentNode.contains( clickedNode ) ) {
			// Close the active submenu
			hideSearchForm();
		}
	}
}

// Function to close an active submenu
function closeOpenDesktopSubmenu() {
	// Try to get an active menu item
	let activeDesktopSubmenuItemLink = document.querySelector(
		'.desktop-menu-link.has-children[aria-expanded=true]'
	);

	// If there is an active submenu
	if ( activeDesktopSubmenuItemLink ) {
		// Set the aria-expanded attribute to false
		// The menu visibility is controlled by this attribute in CSS
		activeDesktopSubmenuItemLink.setAttribute( 'aria-expanded', 'false' );

		// Update the aria label
		activeDesktopSubmenuItemLink.setAttribute(
			'aria-label',
			activeDesktopSubmenuItemLink
				.getAttribute( 'aria-label' )
				.replace( 'Deactivate', 'Activate' )
		);

		// Hide the overlay
		hideOverlay( 'desktop-overlay' );
	}
}

// Function to hide the search form
function hideSearchForm() {
	// If the search toggle is active
	if (
		desktopSearchToggle &&
		desktopSearchToggle.getAttribute( 'aria-expanded' ) === 'true'
	) {

		// Remove the event listener for focus trapping
		document.removeEventListener( 'keydown', handleFocusTrap );

		// Hide the search form
		desktopSearchWrapper.style.display = 'none';

		// Set the aria-expanded attribute to false
		desktopSearchToggle.setAttribute( 'aria-expanded', 'false' );

		// Blur the search input
		desktopSearchInput.blur();

		// Hide the overlay
		hideOverlay( 'desktop-overlay' );
	}
}

// Loop through all desktop menu links with children
document
	.querySelectorAll( '.desktop-menu-link.has-children' )
	.forEach( function ( desktopMenuItemLink ) {
		// Event listener - when this menu item link is clicked
		desktopMenuItemLink.addEventListener( 'click', function ( event ) {
			// Stop the link being followed and any propagation
			event.preventDefault();
			event.stopPropagation();

			// Store the clicked element as a variable
			let clickedDesktopMenuItemLink = event.target;

			// If the submenu isn't open yet
			if (
				clickedDesktopMenuItemLink.getAttribute( 'aria-expanded' ) === 'false'
			) {
				// Hide the search form
				hideSearchForm();

				// Close any open submenus
				closeOpenDesktopSubmenu();

				// If this menu item is less than halfway across the screen
				if ( clickedDesktopMenuItemLink.getBoundingClientRect().x < ( window.innerWidth / 2 ) ) {

					// Align the submenu to the left
					clickedDesktopMenuItemLink.parentNode.childNodes[ 1 ].style.left = '0';
					clickedDesktopMenuItemLink.parentNode.childNodes[ 1 ].style.right = 'auto';

				// Otherwise it's in the right half
				} else {

					// Align the submenu to the right
					clickedDesktopMenuItemLink.parentNode.childNodes[ 1 ].style.left = 'auto';
					clickedDesktopMenuItemLink.parentNode.childNodes[ 1 ].style.right = '0';

				}

				// Set the aria-expanded attribute to true
				// The menu visibility is controlled by this attribute in CSS
				clickedDesktopMenuItemLink.setAttribute( 'aria-expanded', 'true' );

				// Update the aria label
				clickedDesktopMenuItemLink.setAttribute(
					'aria-label',
					clickedDesktopMenuItemLink
						.getAttribute( 'aria-label' )
						.replace( 'Activate', 'Deactivate' )
				);

				// Activate the overlay
				showOverlay( 'desktop-overlay', 50 );

				// Trap focus inside the submenu
				document.addEventListener( 'keydown', handleFocusTrap );

				// Otherwise the submenu is already open
			} else {
				// Close the open submenu
				closeOpenDesktopSubmenu();

				// Remove the event listener for focus trapping
				document.removeEventListener( 'keydown', handleFocusTrap );
			}
		} );
	} );

if ( desktopSearchToggle ) {

	// Add an event listener for a click on the desktop search toggle
	desktopSearchToggle.addEventListener( 'click', function ( event ) {

		// If the search form isn't open yet
		if ( desktopSearchToggle.getAttribute( 'aria-expanded' ) === 'false' ) {
			// Close any open submenus
			closeOpenDesktopSubmenu();

			// Activate the search form
			desktopSearchWrapper.style.display = 'block';

			// Set the aria-expanded attribute to true
			desktopSearchToggle.setAttribute( 'aria-expanded', 'true' );

			// Focus on the search input
			desktopSearchInput.focus();

			// Activate the overlay
			showOverlay( 'desktop-overlay', 50 );

			// Add the event listener for the tab key for focus trapping
			document.addEventListener( 'keydown', handleFocusTrap );

			// Update the aria label
			desktopSearchToggle.setAttribute(
				'aria-label',
				desktopSearchToggle
					.getAttribute( 'aria-label' )
					.replace( 'Activate', 'Deactivate' )
			);

		// Otherwise the search form is already open
		} else {
			// Hide the search form
			hideSearchForm();

			// Update the aria label
			desktopSearchToggle.setAttribute(
				'aria-label',
				desktopSearchToggle
					.getAttribute( 'aria-label' )
					.replace( 'Deactivate', 'Activate' )
			);
		}
	} );
}
